html {
  font-size: 4.5vw;
}

.center-screen {
  display        : flex;
  flex-direction : column;
  justify-content: center;
  align-items    : center;
  text-align     : center;
  min-height     : 100vh;
  background     : #79383D url("https://images.unsplash.com/photo-1448743133657-f67644da3008?dpr=1&auto=format&fit=crop&w=1500&h=938&q=80&cs=tinysrgb&crop=") no-repeat 0 100%;
  background-size: cover;
}

section {
  margin: 1vw 0;
}

section input {
  background: linear-gradient(to bottom, rgba(65, 92, 100, 0.5), rgba(8, 39, 47, 0.5));
  border    : .05rem solid #060000;
  width     : 20vw;
  color     : rgb(160, 160, 160);
}

section input:focus-visible {
  outline   : 0;
  background: linear-gradient(to bottom, rgba(92, 127, 138, 0.9), rgba(8, 39, 47, 0.9));
  color     : #fff;
}

section button {
  background: linear-gradient(to bottom, #242424, #060000);
  border    : .05rem solid #060000;
  color     : #fff;
}

#inputGameId {
  /* position: absolute; */
  font-size: small;
}

.scoreboard {
  /* position: absolute; */
  /* bottom: 2rem;
  left  : 2rem; */
}

.scoreboard__player {
  display      : flex;
  margin-bottom: -0.1rem;
}

.player {
  position      : relative;
  display       : flex;
  align-items   : center;
  border        : 0.1rem solid #060000;
  color         : #fff;
  text-shadow   : -0.05rem -0.05rem rgba(6, 0, 0, 0.75);
  width         : 10em;
  padding-top   : 0.25rem;
  padding-bottom: 0.25rem;
  margin-right  : -0.1rem;
  background    : linear-gradient(to bottom, rgba(65, 92, 100, 0.5), rgba(8, 39, 47, 0.5));
}

.player::after {
  content   : "";
  position  : absolute;
  top       : 0;
  left      : 0;
  right     : 0;
  bottom    : 0;
  background: linear-gradient(to right, rgba(65, 92, 100, 0.75), #060000 75%);
  z-index   : -1;
}

.player--serve::before {
  content      : "";
  position     : absolute;
  right        : 1rem;
  width        : 0;
  height       : 0;
  border-top   : 0.375rem solid transparent;
  border-bottom: 0.375rem solid transparent;
  border-right : 0.375rem solid #F4B400;
}

.player__rank {
  font-size    : 0.6em;
  padding-left : 0.5rem;
  padding-right: 0.5rem;
}

.player__name {
  display     : none;
  margin-right: 0.25rem;
}

.player__surname {
  margin-right: 0.25rem;
}

.player__nationality {
  font-size    : 0.6em;
  margin-left  : auto;
  margin-right : 2rem;
  padding-left : 0.5rem;
  padding-right: 0.5rem;
}

.set {
  display: flex;
  order  : 2;
}

.set__score {
  width         : 1.5rem;
  padding-top   : 0.25rem;
  padding-bottom: 0.25rem;
  text-align    : center;
  background    : linear-gradient(to bottom, #242424, #060000);
  border        : 0.1rem solid #060000;
  color         : #fff;
  text-shadow   : -0.05rem -0.05rem rgba(6, 0, 0, 0.75);
  margin-right  : 0.1rem;
}

.game__score {
  width         : 1.75rem;
  padding-top   : 0.25rem;
  padding-bottom: 0.25rem;
  text-align    : center;
  background    : linear-gradient(to bottom, #F4B400, #F0FF32);
  border        : 0.1rem solid #060000;
  color         : #060000;
  margin-right  : -0.1rem;
}